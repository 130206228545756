// src/pages/Dashboard.js
import React from 'react';
import DashboardContent from '../../components/DashboardContent';


const Dashboard = () => (
    <DashboardContent />
);

export default Dashboard;
