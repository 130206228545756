import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Api/ApiController";

const UserList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const getuserlist = () => {
    ApiService.userlist()
      .then((response) => {
        if (response.data.status === true) {
          setUsers(response.data.data);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getuserlist();
  }, []);

  const handleuserview = (userId) => {
    navigate(`/userview/${userId}`);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="h4" gutterBottom>
        User List
      </Typography>

      {/* Add a responsive wrapper */}
      <Box
        sx={{
          // overflowX: "auto", // Ensure horizontal scrolling
          // maxWidth: "100%", // Keep within viewport width
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="responsive table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Annual Salary</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user._id}>
                 <TableCell data-label="ID">{index + 1}</TableCell>
<TableCell data-label="Name">{user.name || "--"}</TableCell>
<TableCell data-label="Email">{user.email || "--"}</TableCell>
<TableCell data-label="Mobile Number">{"+91" + user.mobile_number}</TableCell>
<TableCell data-label="Gender">{user.gender || "--"}</TableCell>
<TableCell data-label="Annual Salary">{user.yearly_income || "--"}</TableCell>
<TableCell data-label="Action">
  <Button variant="contained" onClick={() => handleuserview(user._id)}>
    <i className="bx bx-detail"></i>
  </Button>
</TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default UserList;
