import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Collapse, IconButton, Drawer } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Sidebar = ({ onLogout }) => {
  const [openUsersMenu, setOpenUsersMenu] = useState(false);
  const [openOrderMenu, setOpenOrderMenu] = useState(false);
  const [openfaqMenu, setopenfaqMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // For responsive drawer
  const navigate = useNavigate();

  const handleUsersClick = () => {
    setOpenUsersMenu((prevOpen) => !prevOpen);
  };

  const handleOrderClick = () => {
    setOpenOrderMenu((prevOpen) => !prevOpen);
  };

  const handlefaqClick = () => {
    setopenfaqMenu((prevOpen) => !prevOpen);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevOpen) => !prevOpen);
  };

  const logout = () => {
    onLogout();
    navigate('/login');
  };

  const sidebarContent = (
    <List>
      <ListItem button component={Link} to="/dashboard" onClick={toggleDrawer}>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem button onClick={handleUsersClick}>
        <ListItemText primary="Users" />
        {openUsersMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openUsersMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/users/list" onClick={toggleDrawer}>
            <ListItemText primary="User List" sx={{ pl: 4 }} />
          </ListItem>
          <ListItem button component={Link} to="/withdraw/list" onClick={toggleDrawer}>
            <ListItemText primary="Withdraw Requests" sx={{ pl: 4 }} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleOrderClick}>
        <ListItemText primary="Orders" />
        {openOrderMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openOrderMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/order/create" onClick={toggleDrawer}>
            <ListItemText primary="Create Order" sx={{ pl: 4 }} />
          </ListItem>
          <ListItem button component={Link} to="/order/list" onClick={toggleDrawer}>
            <ListItemText primary="Orders List" sx={{ pl: 4 }} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handlefaqClick}>
        <ListItemText primary="FAQ" />
        {openfaqMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openfaqMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/faq/create" onClick={toggleDrawer}>
            <ListItemText primary="Create FAQ" sx={{ pl: 4 }} />
          </ListItem>
          <ListItem button component={Link} to="/faq/list" onClick={toggleDrawer}>
            <ListItemText primary="FAQ List" sx={{ pl: 4 }} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button component={Link} to="/business/settings" onClick={toggleDrawer}>
        <ListItemText primary="Settings" />
      </ListItem>

      <ListItem button onClick={logout}>
        <ListItemText primary="Log Out" />
      </ListItem>
    </List>
  );

  return (
    <>
      {/* Menu Icon for Small Screens */}
      <IconButton
        edge="start"
        // color="default"
        aria-label="menu"
        onClick={toggleDrawer}
        sx={{
          display: { md: 'none' }, // Show only on small screens
          position: 'absolute',
          top: 16,
          left: 16,
          color: 'white', 
        }}
      >
        {drawerOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      {/* Permanent Sidebar for Larger Screens */}
      <Box
        sx={{
          width: '250px',
          backgroundColor: '#f5f5f5',
          height: '100vh',
          padding: '16px',
          display: { xs: 'none', md: 'block' }, // Hide on small screens
        }}
      >
        {sidebarContent}
      </Box>

      {/* Drawer for Small Screens */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          display: { md: 'none' }, // Show only on small screens
        }}
      >
        <Box sx={{ width: '250px', padding: '16px' }}>{sidebarContent}</Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
