import axios from 'axios'

const Base_url =  'https://api.onestocks.in/api/admin';// 'http://82.112.227.84/api/admin';
const Api = axios.create({
    baseURL: Base_url,
    headers: {
        'Accept': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('authtoken')}`
    }
});


Api.interceptors.request.use(config => {
    const token = localStorage.getItem('authtoken');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
}, error => {
    return Promise.reject(error);
});
const ApiService = {
    // getSettingData: (key) => Api.get('get-setting-data',{params:{key}}),
        login : (data) => Api.post('/login',data),
        manageSettings : (data) => Api.post('/manageSettings',data),
        getAllSettings : (params) => Api.get('/getAllSettings',{params:params}),
        userlist : () => Api.get('/userlist'),
        userlistForcreateOrder : () => Api.get('/userlistForcreateOrder'),
        createOrder : (data) => Api.post('/createOrder',data),
        orderList : (params) => Api.get('/orderList',{params:params}),
        withdrawList : (params) => Api.get('/withdrawrequestlist',{params}),
        withdrawStatusUpdate : (data) => Api.post('/withdrawStatusUpdate',data),
        userDetails : (params) => Api.get('/userDetails',{params}),
        updateStatusUser : (data) => Api.post('/updateStatusUser',data),
        deleteOrder : (data) => Api.delete('/deleteOrder',{data}),
        createfaq : (data) => Api.post('/createfaq',data),
        updatefaqs : (data) => Api.put('/updatefaqs',data),
        getFaq : () => Api.get('/getFaq'),
        faqbyid : (params) => Api.get('/faqbyid',{params}),
        updateOrderstatus : (data) => Api.post('/orderStatusupdate',data),
        edituserfunds : (data) => Api.post('/edituserfunds',data)

      
        

        

};

export default ApiService;