// src/pages/CreateOrder.js
import React, {  useEffect, useState } from "react";
import "./CreateOrder.css";
import ApiService from "../../Api/ApiController";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";
import { FormControlLabel, Switch } from "@mui/material";
export default function CreateOrderUser() {
    const { token } = useParams();  // Get encrypted data from URL parameter
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      try {
        // First, decode the encrypted data (to handle URL encoding)
        const decodedData = decodeURIComponent(token);

        // Decrypt the data
        const bytes = CryptoJS.AES.decrypt(decodedData, 'secret-key');
        
        // Check if the decryption was successful
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
          const parsedData = JSON.parse(decryptedData);  // Parse the decrypted data
          setUserId(parsedData.userId);
         
          setUserName(parsedData.userName);
        } else {
          console.error("Decryption failed, no valid data returned.");
        }
      } catch (error) {
        console.error('Error decrypting data:', error);
      }
    }
  }, [token]);
  const [formData, setFormData] = useState({
    stock_name: "",
    quantity: "",
    exit_price: "",
    entry_price: "",
    order_type: "",
    amount: "",
    order_status: "",
    notification_send:false
  });
  const [selectedTab, setSelectedTab] = useState("profit");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setFormData({
      ...formData,
      order_type : tab ? tab : 'profit'
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalFormData = {
      ...formData,
      order_type : selectedTab ? selectedTab : 'profit',
      amount : parseInt(formData.amount),
      user_id : userId
    }
    console.log(finalFormData)
    ApiService.createOrder(finalFormData).then((response)=>{
          if(response.data.status === true){
            Swal.fire({
              toast: true,
              position: 'top-end', // You can change this to 'bottom-end', 'bottom-left', etc.
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000, // Duration in milliseconds
              timerProgressBar: true,
            });
            navigate('/order/list');
          }else{
            Swal.fire({
              toast: true,
              position: 'top-end', // You can change this to 'bottom-end', 'bottom-left', etc.
              icon: 'error',
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000, // Duration in milliseconds
              timerProgressBar: true,
            });
          }
    }).catch((error) => {
      Swal.fire({
        toast: true,
        position: 'top-end', // You can change this to 'bottom-end', 'bottom-left', etc.
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 3000, // Duration in milliseconds
        timerProgressBar: true,
      });
    });
    
    // Handle form submission here
  };
  return (
    <div className="trade-form-container">
      <h2>Create Trade Order</h2>
      <form onSubmit={handleSubmit} className="trade-form">
        <div className="form-row">
          <label>
            Stock Name
            <input
              type="text"
              name="stock_name"
              value={formData.stock_name}
              onChange={handleChange}
              placeholder="Enter Stock Name"
            />
          </label>
          <label style={{position:'relative'}}>
           <div className="user_order_username">{userName}</div>
          </label>
        </div>

        <div className="form-row">
          <label>
            Quantity
            <input
              type="text"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              placeholder="Enter Quantity"
            />
          </label>
          <label>
            Order Status
            <select name="order_status" id="" value={formData.order_status} onChange={handleChange}>
              <option value="" selected disabled>
                Select Status
              </option>
              <option value="holding">Holding</option>
              <option value="open">Open</option>
              <option value="exited">Execute</option>
              <option value="closed">Closed</option>
              <option value="rejected">Rejected</option>
            </select>
          </label>
        </div>

        <div className="form-row">
          <label>
            Entry Price
            <input
              type="number"
              name="entry_price"
              value={formData.entry_price}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              placeholder="Enter Entry Price"
            />
          </label>
          <label>
            Exit Price
            <input
              type="number"
              name="exit_price"
              value={formData.exit_price}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              placeholder="Enter Exit Price"
            />
          </label>
        </div>

  
        <div className="createorder_tab-container">
          <div className="createorder_tabs">
            <button
              type="button"
              className={`createorder_tab ${
                selectedTab === "profit" ? "active" : ""
              }`}
              onClick={() => handleTabChange("profit")}
            >
              Profit
            </button>
            <button
              type="button"
              className={`createorder_tab ${
                selectedTab === "loss" ? "active" : ""
              }`}
              onClick={() => handleTabChange("loss")}
            >
              Loss
            </button>
          </div>
          <div className="createorder_tab-content">
            <label>
              Enter Amount for {selectedTab === "profit" ? "Profit" : "Loss"}
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                placeholder={`Enter ${
                  selectedTab === "profit" ? "Profit" : "Loss"
                } Amount`}
              />
            </label>
          </div>
        </div>
        <FormControlLabel control={<Switch checked={formData.notification_send} onChange={(e)=>setFormData({
        ...formData , notification_send : e.target.checked
       })} />} label="Want to send notification?"/>
        <div className="submit_btn_div">
          <button type="submit" className="submit-button">
            Submit Order
          </button>
        </div>
      </form>
    </div>
  );
}
