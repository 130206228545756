// src/App.js
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from './components/SideBar';
import Header from './components/Header';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import UserList from './pages/users/UserList';
import WithdrawList from './pages/users/WithdrawList';
import CreateOrder from './pages/order/CreateOrder';
import Setting from './pages/settings/Setting';
import UserView from './pages/users/UserView';
import CreateOrderUser from './pages/order/CreateOrderUser';
import OrderList from './pages/order/OrderList';
import CreateFaq from './pages/faq/CreateFaq';
import FaqList from './pages/faq/FaqList';
import UpdateFaq from './pages/faq/UpdateFaq';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('auth') === 'true'
  );

  const handleLogin = () => {
    localStorage.setItem('auth', 'true');
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('authtoken')
    setIsAuthenticated(false);
  };

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem('auth') === 'true');
  }, []);

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <Header  />
          <Box sx={{ display: 'flex' }}>
            <Sidebar onLogout={handleLogout}/>
            <Box sx={{ flexGrow: 1, padding: '16px' }}>
              <Routes>
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="/users/list" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
                <Route path="/withdraw/list" element={<ProtectedRoute><WithdrawList /></ProtectedRoute>} />
                <Route path="/order/create" element={<ProtectedRoute><CreateOrder /></ProtectedRoute>} />
                <Route path="/order/list" element={<ProtectedRoute><OrderList /></ProtectedRoute>} />
                <Route path="/faq/create" element={<ProtectedRoute><CreateFaq /></ProtectedRoute>} />
                <Route path="/faq/update/:faqId" element={<ProtectedRoute><UpdateFaq /></ProtectedRoute>} />
                <Route path="/faq/list" element={<ProtectedRoute><FaqList /></ProtectedRoute>} />
                <Route path="/user/order/create/:token" element={<ProtectedRoute><CreateOrderUser /></ProtectedRoute>} />
                <Route path="/business/settings" element={<ProtectedRoute><Setting /></ProtectedRoute>} />
                <Route path="/userview/:userId" element={<ProtectedRoute><UserView /></ProtectedRoute>} />
                {/* <Route path="/editprofile" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} /> */}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </Box>
          </Box>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
