import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Tooltip, Button } from '@mui/material';
import ApiService from '../../Api/ApiController';
import { useNavigate } from 'react-router-dom';
export default function FaqList() {
  const [Faqlist,setFaqlist] = useState([]);
  const navigate =useNavigate();
  const faqlist = ()=>{
    ApiService.getFaq().then((response)=>{
      if(response.data.status === true){
        setFaqlist(response.data.data);
      }else{
        setFaqlist([])
      }
    })
  }
  useEffect(()=>{
    faqlist();
  },[]);

  const handleupdate = (faqId)=>{
    navigate(`/faq/update/${faqId}`);

  }
  return (
    <>
      <Box sx={{ padding: '16px' }}>
      <Typography variant="h4" gutterBottom>
        FAQ List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Faqlist.map((faq, index) => {
        const words = faq.answer.split(' ');
        const truncatedAnswer = words.slice(0, 100).join(' ');
        const hasMore = words.length > 100;

        return (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{faq.question}</TableCell>
            <TableCell>
              {hasMore ? (
                <Tooltip title={faq.answer}>
                  <span>{truncatedAnswer}...</span>
                </Tooltip>
              ) : (
                faq.answer
              )}
            </TableCell>
            <TableCell><Button  variant="contained"
            color="primary" onClick={()=>handleupdate(faq._id)}><i class='bx bx-pencil' ></i></Button></TableCell>
          </TableRow>
        );
      })}
          
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </>
  )
}
