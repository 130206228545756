import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import ApiService from "../../Api/ApiController";
import Swal from "sweetalert2";
import "./orderlist.css";
import Select from "react-select";

export default function OrderList() {
  const [orderList, setOrderList] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    user_id: "",
    start_date: "",
    end_date: "",
  });

  // Fetch orders with filters
  const getorders = () => {
    ApiService.orderList(filters)
      .then((response) => {
        if (response.data.status === true) {
          setOrderList(response.data.data);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  // Delete order functionality
  const deleteorder = (orderId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this order?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        ApiService.deleteOrder({
          order_id: orderId,
        })
          .then((response) => {
            if (response.data.status === true) {
              Swal.fire({
                icon: "success",
                title: response.data.message,
              });
              getorders();
            } else {
              Swal.fire({
                icon: "error",
                title: response.data.message,
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.response.data.message,
            });
          });
      }
    });
  };

  const updateOrderstatus = (orderId, newStatus) => {
    ApiService.updateOrderstatus({
      order_id: orderId,
      status: newStatus,
    })
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: response.data.message,
          });
          getorders();
        } else {
          Swal.fire({
            icon: "error",
            title: response.daata.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.response.daata.message,
        });
      });
  };

  // Fetch users for the user_id dropdown
  const getUsers = () => {
    ApiService.userlistForcreateOrder()
      .then((response) => {
        if (response.data.status === true) {
          const formattedOption = response.data.data.map((user) => ({
            label: user.name,
            value: user._id,
          }));
          setUsers(formattedOption);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getorders();
    getUsers();
  }, []);

  const statusOptions = [
    { value: "", label: "All" },
    { value: "open", label: "Open" },
    { value: "closed", label: "Closed" },
    { value: "rejected", label: "Rejected" },
    { value: "holding", label: "Holding" },
    { value: "exited", label: "Execute" },
  ];

  return (
    <div>
      <Box sx={{ padding: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Order List
        </Typography>

        {/* Filter Section */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap", // Allow wrapping of items
            gap: "16px",
            marginBottom: "16px",
            alignItems: "center",
            justifyContent: "flex-start", // Align items on larger screens
            "@media (max-width: 600px)": {
              flexDirection: "column", // Stack items vertically on small screens
              alignItems: "stretch", // Ensure full width for inputs
            },
          }}
        >
          {/* Status Filter */}
          <Select
            options={statusOptions}
            name="status"
            value={
              statusOptions.find((option) => option.value === filters.status) ||
              ""
            }
            onChange={(selectedOption) =>
              setFilters({ ...filters, status: selectedOption.value })
            }
            placeholder="Select Status"
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                width: "200px",
                minHeight: "40px",
                "@media (max-width: 600px)": { width: "100%" },
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999, // Ensure the dropdown is above other elements
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999, // For portals, ensure dropdown appears above everything
              }),
            }}
            menuPortalTarget={document.body} // Attach the dropdown menu to the body for higher z-index
          />

          {/* User ID Filter */}
          <Select
            options={users}
            name="user_id"
            value={users.find((option) => option.value === filters.user_id)}
            onChange={(selectedOption) =>
              setFilters({ ...filters, user_id: selectedOption.value })
            }
            placeholder="Select Client"
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                width: "200px",
                minHeight: "40px",
                "@media (max-width: 600px)": { width: "100%" }, // Full width on small screens
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
          />

          {/* Start Date Filter */}
          <TextField
            label="Start Date"
            name="start_date"
            type="date"
            value={filters.start_date}
            onChange={handleFilterChange}
            size="small"
            InputLabelProps={{ shrink: true }}
            sx={{
              width: "200px",
              "@media (max-width: 600px)": { width: "100%" }, // Full width on small screens
            }}
          />

          {/* End Date Filter */}
          <TextField
            label="End Date"
            name="end_date"
            type="date"
            value={filters.end_date}
            onChange={handleFilterChange}
            size="small"
            InputLabelProps={{ shrink: true }}
            sx={{
              width: "200px",
              "@media (max-width: 600px)": { width: "100%" }, // Full width on small screens
            }}
          />

          {/* Search Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={getorders}
            sx={{
              width: "120px",
              "@media (max-width: 600px)": { width: "100%" }, // Full width on small screens
            }}
          >
            Search
          </Button>
        </Box>

        {/* Orders Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Stock Name</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Entry Price</TableCell>
                <TableCell>Exit Price</TableCell>
                <TableCell>Profit/Loss</TableCell>
                <TableCell>Order Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.map((order, index) => (
                <TableRow key={order._id}>
                  <TableCell data-label="S.No.">{index + 1}</TableCell>
                  <TableCell data-label="Stock Name">
                    {order.stock_name}
                  </TableCell>
                  <TableCell data-label="User Name">
                    {order.user_id.name}
                  </TableCell>
                  <TableCell data-label="Quantity">{order.quantity}</TableCell>
                  <TableCell data-label="Entry Price">
                    {order.entry_price}
                  </TableCell>
                  <TableCell data-label="Exit Price">
                    {order.exit_price}
                  </TableCell>
                  <TableCell
                    data-label="Profit/Loss"
                    sx={{
                      color: order.order_type === "profit" ? "green" : "red",
                    }}
                  >
                    {order.amount}
                  </TableCell>
                  <TableCell data-label="Order Status">
                    <Select
                      options={statusOptions.filter(
                        (option) => option.value !== ""
                      )}
                      value={statusOptions.find(
                        (option) => option.value === order.order_status
                      )}
                      onChange={(selectedOption) =>
                        updateOrderstatus(order._id, selectedOption.value)
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "120px",
                          minHeight: "30px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "120px",
                        }),
                      }}
                    />
                  </TableCell>
                  <TableCell data-label="Action">
                    <button
                      className="table_btn"
                      onClick={() => deleteorder(order._id)}
                    >
                      <i className="bx bx-trash"></i>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
