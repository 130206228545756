import React, { useEffect, useState } from 'react';
import './Setting.css';
import ApiService from '../../Api/ApiController';
import Swal from 'sweetalert2';

export default function Setting() {
  const [businessName,setbusinessName] = useState();
  const [phoneNumber,setphoneNumber] = useState();
  const [email,setemail] = useState();
  const [termcontdition,setTermContdition] = useState();
  const [aboutus,setAboutus] = useState();
  const [privacypolicy,setPrivacyPolicy] = useState();
  const [id,setId] = useState();

  const getSettings = ()=>{
    ApiService.getAllSettings().then((response)=>{
      if(response.data.status === true){
           console.log(response.data.message);
           setbusinessName(response.data.data.business_name);
           setphoneNumber(response.data.data.phone_number);
           setemail(response.data.data.email);
           setTermContdition(response.data.data.terms_conditions);
           setPrivacyPolicy(response.data.data.privacy_policy);
           setAboutus(response.data.data.about_us);
           setId(response.data.data._id);
      }else{
        console.error(response.data.message);
      }
    }).catch((error)=>console.error(error))
  }

  const handleSubmit = (e)=>{
     e.preventDefault();
     ApiService.manageSettings({
      business_name:businessName,
      phone_number:phoneNumber,
      email:email,
      terms_conditions:termcontdition,
      privacy_policy:privacypolicy,
      about_us:aboutus,
      id: id ? id : ''
     }).then((response)=>{
         if(response.data.status === true){
          Swal.fire({
            text: "Click yes to reflect changes",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
               window.location.reload();
            }
          });
         }else{
            Swal.fire({
              icon:'error',
              title:response.data.message
            })
         }
     }).catch((error)=>{
      Swal.fire({
        icon:'error',
        title:error.response.data.message
      })
     })
  }
  useEffect(()=>{
    getSettings();
  },[])
  return (
    <div className="settings-form-container">
      <h2>Settings</h2>
      <form className="settings-form" onSubmit={handleSubmit}>
        <div className="form-row">
        <label>
          Business Name
          <input type="text" name="business_name" value={businessName} onChange={(e)=>setbusinessName(e.target.value)} placeholder="e.g., One Stock" />
        </label>

        <label>
          Phone Number
          <input type="tel" name="phone_number" value={phoneNumber} onChange={(e)=>setphoneNumber(e.target.value)} placeholder="9898989898" />
        </label>
        </div>
        <div className="form-row">
        <label>
          Email
          <input type="email" name="email" value={email} onChange={(e)=>setemail(e.target.value)} placeholder="onestock@gmail.com" />
        </label>
        </div>
      
      <div className="form-row">
      <label>
          Terms and Conditions
          <textarea name="terms_conditions" value={termcontdition} onChange={(e)=>setTermContdition(e.target.value)} placeholder="Enter terms and conditions here" />
        </label>

        <label>
          Privacy Policy
          <textarea name="privacy_policy" value={privacypolicy} onChange={(e)=>setPrivacyPolicy(e.target.value)} placeholder="Enter privacy policy here" />
        </label>
      </div>

  

     
      <div className="form-row">
      <label>
          About Us
          <textarea name="about_us" value={aboutus} onChange={(e)=>setAboutus(e.target.value)} placeholder="Enter information about the business here" />
        </label>
      </div>
    
      <div className="submit_btn_div">
        <button type="submit" className="submit-button">Save Settings</button>
        </div>
      </form>
    </div>
  );
}
