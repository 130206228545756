import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Swal from 'sweetalert2';
import ApiService from '../../Api/ApiController';

export default function WithdrawList() {
  const [withdrawlist,setwithdrawlist] = useState([]);

  const Withdrawlist = ()=>{
    ApiService.withdrawList().then((response)=>{
      if(response.data.status === true){
          setwithdrawlist(response.data.data)
          
      }else{
        alert(response.data.message)
      }
    }).catch((error)=>{
      console.log(error); 
    })
  }

  useEffect(()=>{
    Withdrawlist()
  },[])

  const handleStatusChange = (event,withdrawId) => {
    const newStatus = event.target.value;
      console.log(withdrawId)
    // Show confirmation dialog using SweetAlert2
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to update the status to ${newStatus}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        if (newStatus === 'rejected') {
          // If status is "Rejected", ask for a reason
          Swal.fire({
            title: 'Please provide a reason for rejection',
            input: 'textarea',
            inputPlaceholder: 'Enter reason...',
            showCancelButton: true,
            confirmButtonText: 'Submit',
          }).then((reasonResult) => {
            if (reasonResult.isConfirmed) {
              // Handle the rejection reason here
              const rejectionReason = reasonResult.value;
              console.log('Rejection Reason:', rejectionReason);
              ApiService.withdrawStatusUpdate({
               withdraw_id:withdrawId,
               status:newStatus,
               rejectionReason:rejectionReason
              }).then((response)=>{
                if(response.data.status === true){
                    Swal.fire({
                      text:response.data.message,
                      icon:'success'
                    });
                    Withdrawlist()
                }else{
                  console.log(response.data.message)
                }
              }).catch((error)=>{
                console.error(error);
                
              })
             
            }
          });
        } else {
          ApiService.withdrawStatusUpdate({
            withdraw_id:withdrawId,
            status:newStatus,
           }).then((response)=>{
             if(response.data.status === true){
                 Swal.fire({
                   text:response.data.message,
                   icon:'success'
                 });
                 Withdrawlist()
             }else{
               console.log(response.data.message)
             }
           }).catch((error)=>{
             console.error(error);
             
           })
        }
      }
    });
  };

  return (
    <div>
      <Box sx={{ padding: '16px' }}>
        <Typography variant="h4" gutterBottom>
          Withdraw Request List
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawlist.map((withdraw,index)=>(
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{withdraw.user.name}</TableCell>
                  <TableCell>{withdraw.amount}</TableCell>
                  <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={withdraw.status}
                      onChange={(e)=>handleStatusChange(e,withdraw._id)}
                      label="Status"
                    >
                      <MenuItem value="pending" disabled>Pending</MenuItem>
                      <MenuItem value="completed">Approved</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
