import React, { useState } from 'react';
import './CreateFaq.css'; // Add your CSS here
import ApiService from '../../Api/ApiController';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function CreateFaq() {
  const [question,setQuestion] = useState();
  const [answer,setAnswer] = useState();
  const navigate = useNavigate();
  const handleSubmit =(e)=>{
    e.preventDefault();
    ApiService.createfaq({
      question,
      answer
    }).then((response)=>{
      if(response.data.status === true){
        navigate('/faq/list')
      }else{
        Swal.fire({
          icon:'error',
          title:response.data.message
        })
      }
    }).catch((error)=>{
      Swal.fire({
        icon:'error',
        title:error.response.data.message
      })
    })
  }
  return (
    <section className="faq-container">
      <form className="faq-form" onSubmit={handleSubmit}>
        <div className="faq-form-group">
          <label htmlFor="question" className="faq-label">
            Question
          </label>
          <input
            type="text"
            name="question"
            id="question"
            value={question}
            onChange={(e)=>setQuestion(e.target.value)}
            placeholder="Enter your question"
            className="faq-input"
          />
        </div>
        <div className="faq-form-group">
          <label htmlFor="answer" className="faq-label">
            Answer
          </label>
          <textarea
            name="answer"
            id="answer"
            value={answer}
            onChange={(e)=>setAnswer(e.target.value)}
            placeholder="Write the answer here"
            className="faq-textarea"
          ></textarea>
        </div>
        <button type="submit" className="faq-submit-button">
          Submit
        </button>
      </form>
    </section>
  );
}
