// src/components/DashboardContent.js
import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

const DashboardContent = () => (
  <Box sx={{ padding: '16px' }}>
    <Typography variant="h4" gutterBottom>
      Dashboard Overview
    </Typography>

    {/* Main dashboard grid */}
    <Grid container spacing={3}>
      {/* Widget 1 */}
      <Grid item xs={12} sm={6} md={4}>
        <Paper sx={{ padding: '16px', textAlign: 'center' }}>
          <Typography variant="h6">Total Users</Typography>
          <Typography variant="h3">1,234</Typography>
        </Paper>
      </Grid>

      {/* Widget 2 */}
      <Grid item xs={12} sm={6} md={4}>
        <Paper sx={{ padding: '16px', textAlign: 'center' }}>
          <Typography variant="h6">Active Users</Typography>
          <Typography variant="h3">890</Typography>
        </Paper>
      </Grid>

      {/* Widget 3 */}
      <Grid item xs={12} sm={6} md={4}>
        <Paper sx={{ padding: '16px', textAlign: 'center' }}>
          <Typography variant="h6">Monthly Sales</Typography>
          <Typography variant="h3">$12,345</Typography>
        </Paper>
      </Grid>
    </Grid>
  </Box>
);

export default DashboardContent;
