import React, { useState,useEffect } from 'react';
import './userview.css';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import ApiService from '../../Api/ApiController';
import Swal from 'sweetalert2';
export default function UserView() {
  const {userId} = useParams();
  const [open, setOpen] = useState(false);
  const  navigate = useNavigate();
  const [user,setUser] = useState({});
  const [wallet,setwallet] = useState(0);
    
    const userdetails = ()=>{
      ApiService.userDetails({
        user_id:userId
      }).then((response)=>{
        if(response.data.status === true){
              setUser(response.data.data);
              setwallet(response.data.data.wallet)
              console.log(response.data.data)
        }else{
          setUser({})
        }
      })
    }

useEffect(()=>{
  userdetails()
},[])
  // Handle redirection with encrypted data
  const handleRedirect = () => {
    // Get the encrypted data
    const token = CryptoJS.AES.encrypt(JSON.stringify({
      userId: user._id,
      userName: user.name
      }), 'secret-key').toString();

    // Redirect to the create order page with encrypted info in the URL
    navigate(`/user/order/create/${encodeURIComponent(token)}`);
  };

  const userStatusupdate = (event)=>{
    const newStatus = event.target.value;

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to update the status to ${newStatus}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if(result.isConfirmed){
        ApiService.updateStatusUser({
          user_id:user._id,
          status: newStatus
        }).then((response)=>{
          if(response.data.status === true){
            Swal.fire({
              icon:'success',
              title:response.data.message
          });
          userdetails();
          
          }else{
            Swal.fire({
                icon:'error',
                title:response.data.message
            });
          }
  }).catch((error)=>{
    Swal.fire({
      icon:'error',
      title:error.response.data.message
  });
  })
      }
    });
    
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const editfund = ()=>{
    ApiService.edituserfunds({
      amount:parseInt(wallet),
      user_id:user._id

    }).then((response)=>{
      if(response.data.status === true){
        Swal.fire({
          toast: true,
          position: 'top-end', // You can change this to 'bottom-end', 'bottom-left', etc.
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 3000, // Duration in milliseconds
          timerProgressBar: true,
        });
        handleClose();
        userdetails();
      }else{
        Swal.fire({
          toast: true,
          position: 'top-end', // You can change this to 'bottom-end', 'bottom-left', etc.
          icon: 'error',
          title: response.data.message,
          showConfirmButton: false,
          timer: 3000, // Duration in milliseconds
          timerProgressBar: true,
        });
      }

    }).catch((error)=>{
      console.log(error)
      Swal.fire({
        toast: true,
        position: 'top-end', // You can change this to 'bottom-end', 'bottom-left', etc.
        icon: 'error',
        title:error.response.data.message,
        showConfirmButton: false,
        timer: 3000, // Duration in milliseconds
        timerProgressBar: true,
      });
    })
  }
  return (
    <>
    {user.status === 'approve' &&( <section className="header_user_view">
      <button  onClick={handleOpen}>Edit funds</button>
      <button onClick={handleRedirect}> <span><i class='bx bx-plus-circle'></i></span> Create Order</button>
    </section>)}
    <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Funds</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Enter Amount"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={wallet} 
                        onChange={(e)=>setwallet(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={editfund} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
    <section className="user_view-container">
      <div className="user_view-card">
        <h1 className="user_view-title">User Details</h1>
        <div className="user_view-info">
            <div className='user_view_status_row'>
          <p><strong>Name:</strong> {user.name}</p>
          {user.status && (
  <FormControl sx={{ width: '200px' }}>
    <InputLabel>Status</InputLabel>
    <Select
      value={user.status}
      label="Status"
      onChange={userStatusupdate}
    >
      <MenuItem value="pending">Pending</MenuItem>
      <MenuItem value="approve">Approved</MenuItem>
    </Select>
  </FormControl>
)}

            </div>
          <p><strong>Mobile Number:</strong> {user.mobile_number}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Adhar Number:</strong> {user.adhar_number}</p>
          <p><strong>PAN Card Number:</strong> {user.pan_card_number}</p>
          <p><strong>Wallet:</strong> {user.wallet}</p>
          <p><strong>Status:</strong> {user.status}</p>
          <p><strong>Is Deleted:</strong> {user.is_deleted ? "Yes" : "No"}</p>
          <p><strong>Username:</strong> {user.username}</p>
          <p><strong>Created At:</strong> {new Date(user.createdAt).toLocaleString()}</p>
        </div>
      </div>

      <div className="user_view-card user_view-images">
        <h2 className="user_view-title">Document Images</h2>
        <div className="user_view-image-group">
          <div className="user_view-image-item">
            <strong>Adharcard Photo:</strong>
            <img src={user.adharcard_photo} alt="Adharcard" />
          </div>
          <div className="user_view-image-item">
            <strong>PAN Card Photo:</strong>
            {user.pan_card_photo ? (
              <img src={user.pan_card_photo} alt="PAN Card" />
            ) : (
              <span>No Image Available</span>
            )}
          </div>
          <div className="user_view-image-item">
            <strong>Bank Statement Photo:</strong>
            <img src={user.bank_statement_photo} alt="Bank Statement" />
          </div>
          <div className="user_view-image-item">
            <strong>Signature Photo:</strong>
            {user.signature_photo ? (
              <img src={user.signature_photo} alt="Signature" />
            ) : (
              <span>No Image Available</span>
            )}
          </div>
        </div>
      </div>

      <div className="user_view-card">
        <h2 className="user_view-title">Bank Details</h2>
        <table className="user_view-table">
          <thead>
            <tr>
              <th>Account Number</th>
              <th>Bank Name</th>
              <th>IFSC Code</th>
            </tr>
          </thead>
          <tbody>
          {user.bank_account?.map((bank, index) => (
        <tr
          key={index}
          style={{
            backgroundColor: bank.is_primary ? "#e0f7e9" : "transparent",
          }}
        >
          <td>
            {bank.is_primary && (
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  marginRight: "5px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                }}
              ></span>
            )}
            {bank.account_number}
          </td>
          <td>{bank.bank_name}</td>
          <td>{bank.ifsc_code}</td>
        </tr>
      ))}
          </tbody>
        </table>
      </div>
    </section>
    </>

  );
}
